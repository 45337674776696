
import {
	Component, Vue
}
	from "vue-property-decorator";
import Header from "@/components/header.vue"; // @ is an alias to /src
import SidebarSales from "@/components/sidebar-sales.vue"; // @ is an alias to /src
import SaleService from "@/services/sale-service"; @
	Component({
		components: {
			Header,
			SidebarSales,
		},
	})
export default class GenerateCustomerInvoice extends Vue {

	trans: any = {};
	loader = false;
	option_covered_period: any = [];
	items = [{
		sno: 1,
		item: "",
		item_arabic: "",
		unit: 0,
		price: 0,
		req_quantity: 0,
		quantity: 0,
		amount: 0,
	}];

	public invoice_start = "";
	public invoice_end = "";

	changedValue() { }
	change_project() { }
	changedValueInvoice() { }
	onchange(index: any, value: any) {		
		this.items[index].amount = (Math.round((value.quantity * value.price + Number.EPSILON) * 100) / 100);
	}
	saveinvoice() {
		this.loader = true;
		this.trans['invoice_start'] = this.invoice_start;
		this.trans['invoice_end'] = this.invoice_end;

		let obj = {
			invoice: this.trans,
			items: this.items
		}
		SaleService.create_customer_invoice(obj).then((response) => {
			let data = response.data;
			this.loader = false;
			this.$router.push({ path: `/accounts/sales/customer-invoice/${data.invoice}` });

		}).catch((e) => {
			this.loader = false;
			console.log(e);
		});
	}
	add_item() {
		this.items.push({
			sno: this.items.length + 1,
			item: "",
			item_arabic: "",
			price: 0,
			unit: 0,
			req_quantity: 0,
			quantity: 0,
			amount: 0,
		});
	}
	mounted() {

	}
	deleteRow(index:any) {
    this.items.splice(index, 1);
  }
}
